// TODO (jackyang) Until the Strapi DB migration, adding this logic in to handle
//  en-US consistently on NextJS side

// Now, `'en'` is the default locale in Strapi, but we are calling this `'en-US'`
// in the Next.js app.
// Eventually we want to use `'en-US'` as the default locale in Strapi too.
// Using this file as a place to centralize this mapping logic.
import type { Localization } from '@/utilities/strapi/types/utilsTypes';

export const defaultStrapiLocale = 'en';

export function isDefaultLocale(locale: string) {
  return locale === 'en' || locale === 'en-US';
}

export function mapAppLocaleToStrapiLocale(appLocale: string) {
  if (appLocale === 'en-US') {
    return 'en';
  }
  return appLocale;
}

export function mapStrapiLocaleToAppLocale(strapiLocale: string) {
  if (strapiLocale === 'en') {
    return 'en-US';
  }
  return strapiLocale;
}

const defaultLanguageLocales: Record<string, string> = {
  en: 'en-US',
  es: 'es-419',
  zh: 'zh-Hant-HK',
};

export function convertHrefLangs(localizations: Localization[]) {
  const languages: Record<string, Localization[]> = {};
  languages.en = []; // Sort en at the top
  localizations.forEach((localization) => {
    const locale = mapStrapiLocaleToAppLocale(localization.locale);
    const [language] = locale.split('-', 1);
    languages[language] = languages[language] || [];
    languages[language].push(localization);
  });
  // Sort each language group so that the default language locale is listed last
  for (const [language, languageLocalizations] of Object.entries(languages)) {
    const defaultLanguageLocale = defaultLanguageLocales[language];
    if (defaultLanguageLocale) {
      languageLocalizations.sort((a, b) => {
        const localeA = mapStrapiLocaleToAppLocale(a.locale);
        const localeB = mapStrapiLocaleToAppLocale(b.locale);
        if (localeA === defaultLanguageLocale) {
          return 1;
        }
        if (localeB === defaultLanguageLocale) {
          return -1;
        }
        return 0;
      });
    }
  }
  const hrefLangs: Array<[Localization, string]> = [];
  for (const [language, languageLocalizations] of Object.entries(languages)) {
    languageLocalizations.forEach((localization, i) => {
      let hrefLang: string;
      if (i < languageLocalizations.length - 1) {
        hrefLang = mapStrapiLocaleToAppLocale(localization.locale);
      } else {
        hrefLang = language;
      }
      hrefLangs.push([localization, hrefLang]);
    });
  }
  // Sort en to the top
  hrefLangs.sort((a, b) => {
    const [, langA] = a;
    const [, langB] = b;
    if (langA === 'en') {
      return -1;
    }
    if (langB === 'en') {
      return 1;
    }
    return 0;
  });
  const defaultLocalization = localizations.find(({ locale }) => isDefaultLocale(locale));
  if (defaultLocalization) {
    hrefLangs.push([defaultLocalization, 'x-default']);
  }
  return hrefLangs;
}
