import type {
  LocaleResultItem,
  StrapiFooter,
  StrapiHeader,
  StrapiPage,
  StrapiSiteSettings,
  StrapiIntegration,
  StrapiIntegrationSettings,
} from './apiResponseTypes';

export type StrapiSingleType = StrapiFooter | StrapiHeader | StrapiSiteSettings | StrapiIntegrationSettings;
export type StrapiCollectionType = StrapiPage[] | LocaleResultItem[] | StrapiIntegration[];

export type StrapiData = StrapiSingleType | StrapiCollectionType;

export interface StrapiError {
  name?: string;
  message?: string;
  details?: Record<string, unknown>;
}

export interface StrapiResponsePagination {
  start: number;
  limit: number;
  total: number;
}

export interface StrapiResponseMeta {
  pagination?: StrapiResponsePagination;
}

export interface StrapiBaseResponse {
  // eslint-disable-next-line @typescript-eslint/ban-types
  data?: object | null;
  meta?: {
    pagination?: Partial<StrapiResponsePagination>;
  };
  error?: StrapiError;
}

export interface StrapiResponse extends StrapiBaseResponse {
  data?: StrapiData | null;
}

export interface StrapiSuccessResponse {
  data: StrapiData;
  meta: StrapiResponseMeta;
}

export enum StrapiModel {
  Integrations = 'integrations',
  Pages = 'pages',
  SiteSettings = 'site-settings',
  IntegrationSettings = 'integration-settings',
  Header = 'header',
  Footer = 'footer',
  Locales = 'i18n/locales',
}

export type StrapiPageModels = StrapiModel.Pages | StrapiModel.Integrations;

export interface StrapiParamsObject {
  [key: string]:
    | string
    | boolean
    | number
    | string[]
    | boolean[]
    | number[]
    | StrapiParamsObject
    | StrapiParamsObject[];
}
export type StrapiParams = StrapiParamsObject | string;
