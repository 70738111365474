/**
 * Translates untyped errors that may come from external sources into a standardized `Error`.
 * If the param is already an Error, it will be returned as-is.
 * If it's a string, is falsy, or has a message field, a new Error will be created with that value.
 * Otherwise a new Error will be created stringifying the object.
 * @param error The thrown error to wrap in an object
 * @returns an Error object
 */
export function asError(error: unknown): Error {
  if (error instanceof Error) {
    return error;
  } else if (typeof error === 'string') {
    return new Error(error);
    // @ts-expect-error: checking unknown object key
  } else if (typeof error === 'object' && error != null && typeof error.message === 'string') {
    // @ts-expect-error: reading previously checked object key
    return new Error(error.message as string);
  } else if (error === undefined) {
    return new Error(error);
  } else {
    return new Error(JSON.stringify(error));
  }
}
