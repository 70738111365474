/**
 * @author @Patreon/fe-core
 **/

/**
 * This type marks every property in T as optional, except for the properties
 * listed in the string union P which retain their current optional/required
 * status
 *
 * @example
 * interface Person {
 *     name: string
 *     age: number
 *     email?: string
 * }
 *
 * // name is required, but age and email are optional
 * type PersonWithOptionalAge = PartialExceptFor<Person, 'name' | 'email'>
 */
export type PartialExceptFor<T, P extends keyof T> = Pick<T, P> & Partial<T>;

/**
 * This type marks every property in T as required, except for the properties
 * listed in the string union P which retain their current optional/required
 * status
 *
 * @example
 * interface Person {
 *     name?: string
 *     age: number
 *     email?: string
 * }
 *
 * // name and age are required, but email is optional
 * type PersonWithOptionalEmail = RequiredExceptFor<Person, 'name'>
 */
export type RequiredExceptFor<T, P extends keyof T> = Omit<Required<T>, P> & Pick<T, P>;

/**
 * This function exists to ensure that a value is "never" at both compile time
 * and run time. Use this when you want to make sure that switch and if/else
 * statements cover all cases in a string union
 *
 * @example
 * type Foo = 'a' | 'b'
 *
 * const x: Foo = 'a'
 *
 * switch (x) {
 *   case 'a':
 *     break
 *   case 'b':
 *     break;
 *   default:
 *     assertNever(x) // Works fine
 * }
 *
 * switch (x) {
 *   case 'a':
 *     break
 *   default:
 *     assertNever(x) // Runtime and compile time error
 * }
 */
export function assertNever(value: never): never {
  throw new Error(`"${value}" was not handled correctly (type should be never but isn't)`);
}

/**
 * This type marks every property, deeply, in T as optional.
 * Useful in tests when mocking just the relevant parts of a larger object.
 *
 * @example
 * interface Person {
 *     name: string
 *     age: number
 *     email: string
 *     address: {
 *          street: string
 *          zip: number
 *      }
 * }
 *
 * // everything, including "street" and "zip" nested within "address," are optional
 * type TotallyOptionalPerson = DeepPartial<Person>
 */
export type DeepPartial<T> = Partial<{ [P in keyof T]: DeepPartial<T[P]> }>;

export function isDefined<T>(val: T | undefined | null): val is T {
  return val !== undefined && val !== null;
}
