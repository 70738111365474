import QueryString from 'qs';
import type { ObjectSchema } from 'yup';
import { mixed, number as ynumber, object, string } from 'yup';

import { isPreview } from '@/shared/environment';
import { assertNever } from '@/types/utilities';

import type {
  LocaleResultItem,
  StrapiFooter,
  StrapiHeader,
  StrapiIntegration,
  StrapiIntegrationSettings,
  StrapiPage,
  StrapiSiteSettings,
} from './types/apiResponseTypes';
import type {
  StrapiBaseResponse,
  StrapiData,
  StrapiError,
  StrapiPageModels,
  StrapiParams,
  StrapiParamsObject,
  StrapiResponse,
  StrapiSuccessResponse,
} from './types/serviceClientTypes';
import { StrapiModel } from './types/serviceClientTypes';
import { asError } from '../data-validation/asError';

const apiUrl = (() => {
  if (isPreview && typeof window !== 'undefined') {
    // If we're in preview mode in the browser, pipe api requests through our pages function.
    return '/api/';
  }

  const url = new URL(process.env.STRAPI_API_URL || 'https://strapi.patreondev.com/api');
  if (url.hostname === 'localhost') {
    // Since Node.js v17, `localhost` resolves to ipv6 `::1` which breaks
    // local Strapi connections because Strapi doesn’t listen on ipv6 by
    // default. Workaround by using numeric ipv4 address.
    url.hostname = '127.0.0.1';
  }
  const urlString = url.toString();
  if (!urlString.endsWith('/')) {
    return `${urlString}/`;
  }
  return urlString;
})();

export class StrapiApiError extends Error {
  public name: string;
  public status?: number;
  public statusText?: string;
  public serverMessage?: string;
  public details?: Record<string, unknown>;
  public model?: string;
  public query?: string;

  constructor({
    response,
    name,
    message,
    details,
    model,
    query,
  }: {
    response?: Response;
    name: string;
    message?: string;
    details?: Record<string, unknown>;
    model?: string;
    query?: string;
  }) {
    let suffix = [name, message].filter(Boolean).join(': ');
    if (!suffix && response) {
      suffix = `status ${response.status} ${response.statusText}`;
    }
    super(`Error fetching from CMS: ${suffix}`);
    if (response) {
      this.status = response.status;
      this.statusText = response.statusText;
    }
    this.name = name;
    this.serverMessage = message;
    this.details = details;
    this.model = model;
    this.query = query;
  }
}

async function queryWithRetry(
  model: StrapiModel,
  attemptsLeft: number,
  timeoutMs: number,
  params?: StrapiParams,
): Promise<StrapiSuccessResponse> {
  let query: string;
  if (params) {
    if (typeof params === 'string') {
      query = params;
    } else {
      query = QueryString.stringify(params, { encodeValuesOnly: true });
    }
  } else {
    query = '';
  }
  if (query) {
    query = `?${query}`;
  }
  let response: Response | undefined;

  try {
    response = await fetch(`${apiUrl}${model}${query}`, {
      headers: {
        Authorization: `Bearer ${process.env.STRAPI_API_TOKEN}`,
      },
    });
    const json: unknown = await response.json();
    const mapped = mapResponse(model, json);
    const { error, ...result } = mapped;

    // data is bad
    if (!response.ok || checkError(error) || !result.data) {
      throw new StrapiApiError({ response, ...error, name: error?.name || '', model, query });
    }

    return result as StrapiSuccessResponse;
  } catch (e) {
    if (attemptsLeft > 0) {
      // wait, and try again, recursively
      await new Promise((resolve) => setTimeout(resolve, timeoutMs));
      const resp = await queryWithRetry(model, attemptsLeft - 1, timeoutMs * 1.5, params);
      return resp;
    }

    const err = asError(e);
    throw new StrapiApiError({
      response,
      name: err.name,
      message: err.message,
    });
  }
}

export async function queryFromStrapi<T extends StrapiPageModels>(
  model: T,
  params: StrapiParams,
): Promise<T extends StrapiModel.Pages ? StrapiPage[] : StrapiIntegration[]>;
export async function queryFromStrapi(
  model: StrapiModel.SiteSettings,
  params: StrapiParams,
): Promise<StrapiSiteSettings>;
export async function queryFromStrapi(
  model: StrapiModel.IntegrationSettings,
  params: StrapiParams,
): Promise<StrapiIntegrationSettings>;
export async function queryFromStrapi(model: StrapiModel.Header, params: StrapiParams): Promise<StrapiHeader>;
export async function queryFromStrapi(model: StrapiModel.Footer, params: StrapiParams): Promise<StrapiFooter>;
export async function queryFromStrapi(model: StrapiModel.Locales): Promise<LocaleResultItem[]>;
export async function queryFromStrapi(model: StrapiModel, params?: StrapiParams): Promise<StrapiData> {
  if (params && typeof params !== 'string') {
    const paramsObject: StrapiParamsObject = { ...params };
    if (paramsObject.fetchAll && (model === StrapiModel.Pages || model === StrapiModel.Integrations)) {
      // Want to fetch all by making a series of paginated requests
      delete paramsObject.fetchAll;
      return queryAllFromStrapi(model, paramsObject);
    }
  }
  const { data } = await queryWithRetry(model, 10, 500, params);
  return data;
}

async function queryAllFromStrapi<
  M extends StrapiModel,
  R extends M extends StrapiModel.Pages ? StrapiPage[] : StrapiIntegration[],
>(model: M, params: StrapiParamsObject): Promise<R> {
  // Fetch all by making a series of paginated requests
  const pagination = {
    start: 0,
    limit: 100,
  };
  params = { ...params, pagination };
  const all = [];
  for (;;) {
    const { data, meta } = await queryWithRetry(model, 10, 500, params);
    const page = data as StrapiPage[] | StrapiIntegration[];
    all.push(...page);
    if (!meta.pagination) {
      break;
    }
    pagination.start = meta.pagination.start + page.length;
    if (pagination.start >= meta.pagination.total) {
      break;
    }
  }
  return all as R;
}

function checkError(err?: StrapiError): boolean {
  // TODO make this more rigorous for cases where there are actual errors
  return !!(err?.details || err?.message || err?.name);
}

function mapResponse(model: StrapiModel, json: unknown): StrapiResponse {
  if (model === StrapiModel.Locales) {
    const data = json as LocaleResultItem[];
    return { data, meta: { pagination: { start: 0, limit: data.length, total: data.length } } };
  }
  const responseSchema: ObjectSchema<StrapiBaseResponse> = object({
    // This can be either an array or object. Should strengthen this
    data: mixed().optional().nullable(),
    error: object({
      // required -> optional for now since this is there even if tehre are no errors
      name: string().optional(),
      message: string().optional(),
      details: object().optional(),
    }).optional(),
    meta: object({
      pagination: object({
        start: ynumber().optional(),
        limit: ynumber().optional(),
        total: ynumber().optional(),
      }).optional(), // no pagination provided when requesting single objects
    }),
  });

  const { data: unvalidatedData, meta, error } = responseSchema.validateSync(json);
  const data = unvalidatedData ? mapData(model, unvalidatedData) : undefined;

  return {
    data,
    meta,
    error,
  };
}

function mapData(model: StrapiModel, unvalidatedData: unknown): StrapiData {
  // TODO: needs data validation and property name transformations
  switch (model) {
    case StrapiModel.Pages:
      return unvalidatedData as StrapiPage[];

    case StrapiModel.SiteSettings:
      return unvalidatedData as StrapiSiteSettings;

    case StrapiModel.Header:
      return unvalidatedData as StrapiHeader;

    case StrapiModel.Footer:
      return unvalidatedData as StrapiFooter;

    case StrapiModel.Integrations:
      return unvalidatedData as StrapiIntegration[];

    case StrapiModel.Locales:
      return unvalidatedData as LocaleResultItem[];

    case StrapiModel.IntegrationSettings:
      return unvalidatedData as StrapiIntegrationSettings;

    default:
      assertNever(model);
  }
}
