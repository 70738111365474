import type { StrapiMinimalPageInfo } from '@/utilities/strapi/types/queryResponseTypes';
import type { StrapiPageModels } from '@/utilities/strapi/types/serviceClientTypes';
import { StrapiModel } from '@/utilities/strapi/types/serviceClientTypes';

function splitUrlPrefix(urlPrefix: string): string[] {
  // Remove leading and trailing white spaces
  const trimmedPrefix = urlPrefix.trim();

  // Split the prefix by slashes and filter out empty strings
  const items = trimmedPrefix.split('/').filter((item) => item !== '');

  return items;
}

export default function getPageSlugs(
  page: StrapiMinimalPageInfo,
  pageType: StrapiPageModels = StrapiModel.Pages,
): string[] {
  if (pageType === StrapiModel.Integrations) {
    return ['apps', page.Slug];
  }
  const pageRelationsBasedSlugs = [];
  let { Slug: slug, Parent: parent } = page;
  const originalSlug = slug;

  // TODO (jackyang) re-evaluate this logic once page relations are more stable
  // We want a non-page relation powered fallback here
  // console.log('fallback', parent?.urlPrefixFallback);
  const parentUrlPrefixFallback = parent?.urlPrefixFallback;
  const parentUrlPrefixFallbackSlugs = parentUrlPrefixFallback ? splitUrlPrefix(parentUrlPrefixFallback) : [];

  // home has special handling
  if (slug !== 'home') {
    pageRelationsBasedSlugs.push(slug);
    while (parent) {
      const attributes = parent.page?.data?.attributes || parent.url_segment?.data?.attributes;
      if (!attributes) {
        break;
      }
      slug = attributes.Slug;
      if (slug) {
        pageRelationsBasedSlugs.unshift(slug);
      }
      parent = attributes.Parent;
      if (pageRelationsBasedSlugs.length === 3) {
        break;
      }
    }
  }

  // Separate handling for the fallback prefix
  if (slug !== 'home') {
    parentUrlPrefixFallbackSlugs.push(originalSlug);
    if (parentUrlPrefixFallbackSlugs.length >= 3) {
      throw new Error('There is an error in the urlPrefixFallback field in Strapi. There is a limit to the depth');
    }
  }

  return parentUrlPrefixFallbackSlugs.length > pageRelationsBasedSlugs.length
    ? parentUrlPrefixFallbackSlugs
    : pageRelationsBasedSlugs;
}
